import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map, firstValueFrom, BehaviorSubject } from 'rxjs';
import { InboxItem, MessageList, MessageRecord, RecipientList, RecipientSearchQuery, ReplyMessage, ScanStatus, SendableMessage, UploadedDocument } from 'src/app/models/messages';
import { PrepopulatedMessage } from 'src/app/models/prepopulated-message';
import { environment } from 'src/environments/environment';
import moment from 'moment';
import { DocumentUploadResponse } from 'src/app/models/document-upload-response';
import { DateHandlerService } from '../date-handler/date-handler.service';
import { TestResult } from 'src/app/models/test-result';
import { Appointment } from 'src/app/models/appointment';
import { Medication } from 'src/app/models/medication';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  env = environment;
  // testResults: any;
  prepopulatedMessage: PrepopulatedMessage;
  newMessages: BehaviorSubject<InboxItem[]> = new BehaviorSubject([]);
  testResultInquiry: TestResult;
  appointmentInquiry: Appointment;
  rxRefillInquiry: Medication;

  constructor(
    private http: HttpClient,
    private router: Router,
    private dateHandler: DateHandlerService
  ) {}

  cleanMessage(msg: string): string {
    try {
      if (msg !== undefined && msg) {
        //const decodedMsg = decodeURIComponent(escape(msg));
        const escapeMsg = escape(msg)
        //console.log('escape Message', escapeMsg);

        const modifiedEscapeMsg =
          escapeMsg.replace(/%u2018/g, '%27') //single left quote
            .replace(/%u201C/g, '%22') //double left quote
            .replace(/%u201D/g, '%22') //double right quote
            .replace(/%u2019/g, '%27') //single right quote
            .replace(/%u2013/g, '%2D') // dash

        const decodedMsg = decodeURIComponent(modifiedEscapeMsg);

        const string1 = /\‘/gi;
        const string2 = /\’/gi;
        const string3 = /\`/gi;
        const string4 = /\“/gi;
        const string5 = /\”/gi;
        const cleanedMsg = decodedMsg
          .replace(string1, "'")
          .replace(string2, "'")
          .replace(string3, "`")
          .replace(string4, '"')
          .replace(string5, '"');

        return cleanedMsg;
      } else {
        return msg;
      }
    } catch (err) {
      return msg;
    }
  }

  cleanMessageList(msgList: MessageList): MessageList {
    const newMsgList = Object.assign({}, msgList);
    if (newMsgList && newMsgList.children && newMsgList.children.length > 0) {
      newMsgList.children.forEach((m) => {
        m.Subject = this.cleanMessage(m.Subject);
      });
    }
    newMsgList.cleaned = true;
    return newMsgList;
  }

  beautifyMessage(msg: MessageRecord) {
    // console.log('beautifySubject', msg);
    const message = Object.assign({}, msg);
    // Prepare subject
    const subjectParts = message.Subject.split(' - ');
    const subjectMetaParts = subjectParts.splice(0, 3);
    let subject = subjectParts.length > 0 ? subjectParts.join(' - ') : message.Subject;

    // Get Convo ID
    const match = subject.match(/\[(\d+)\]/);
    let convoId = match ? match[0].replace('[', '').replace(']', '') : message.id;

    // Beautify Subject
    subject = subject.replace(/\s*\[\d+\]\s*/, '');
    const isReply = subjectMetaParts[0] ? subjectMetaParts[0].indexOf('RE:') > -1 : false;

    // console.log('beautifySubject: parts: ', subjectParts, subjectMetaParts);

    // Set Beautified Parts
    const beautified = {
      Subject: isReply ? 'Re: ' + subject : subject,
      Text: '',
      From: subjectMetaParts[2],
      To: '',
      ConvoId: convoId,
      IsReply: isReply
    }

    // Update original message
    msg.Beautified = beautified;
  }

  getMessagesByFolder(folder: string): Observable<MessageList> {
    const url = `${this.env.apiUrl}/messages/folders/${folder}`;
    return this.http.get(url, { observe: 'response', withCredentials: true}).pipe(
      map((data: any) => {
        const msgList = data.body;
        if (msgList) {
          for (let m of msgList.children) {
            m = this.beautifyMessage(m);
          }
          const cleanMsgList = this.cleanMessageList(msgList);
          data.body = cleanMsgList;
        }
        return data.body;
      }),
    );
  }

  async getNewMessages(): Promise<InboxItem[]> {
    try {
      const inboxMessages = await this.getInboxMessages();
      const unread = inboxMessages ? inboxMessages.filter(m => !m.IsRead) : [];
      this.newMessages.next(unread);
      return unread;
    } catch (err) {
      console.error('Error: Unable to get new messages', err);
      return [];
    }
  }

  async getInboxMessages(): Promise<InboxItem[]> {
    try {
      const res = await firstValueFrom(this.getMessagesByFolder('Inbox'));
      res.children.sort((a, b) => this.dateHandler.sortByMoment(a.Time, b.Time));
      return Promise.resolve(res.children);
    } catch (err) {
      console.error('getInboxMessages: Error loading inbox messages', err);
      return Promise.reject(err);
    }
  }

  async getSentMessages(): Promise<InboxItem[]> {
    try {
      const res = await firstValueFrom(this.getMessagesByFolder('Sent'));
      res.children.sort((a, b) => this.dateHandler.sortByMoment(a.Time, b.Time));
      return Promise.resolve(res.children);
    } catch (err) {
      console.error('getSentMessages: Error loading sent messages', err);
      return Promise.reject(err);
    }
  }

  async getArchivedMessages(): Promise<InboxItem[]> {
    try {
      const inboxArchived: MessageList = await firstValueFrom(this.getMessagesByFolder('InboxArchive'));
      const sentArchived: MessageList = await firstValueFrom(this.getMessagesByFolder('SentArchive'));
      const archived = inboxArchived.children.concat(sentArchived.children);
      console.log('getArchivedMessages', archived);
      archived.sort((a, b) => this.dateHandler.sortByMoment(a.Time, b.Time));
      return Promise.resolve(archived);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  getMessageById(msgId: string): Observable<MessageRecord> {
    const url = `${this.env.apiUrl}/messages/${msgId}`;
    return this.http.get(url, { observe: 'response', withCredentials: true}).pipe(
      map((data: any) => {
        const msg: MessageRecord = data.body;
        if (msg) {
          msg.Text = this.cleanMessage(msg.Text);
          msg.Subject = this.cleanMessage(msg.Subject);
          data.body = msg;
        }
        this.beautifyMessage(msg);
        return data.body;
      }),
    );
  }

  archiveMessages(ids: number[]): Observable<any> {
    const url = `${this.env.apiUrl}/messages/archive`;
    const body = { ids };
    return this.http.post(url, body, { observe: 'response', withCredentials: true}).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  unarchiveMessages(ids: number[]): Observable<any> {
    const url = `${this.env.apiUrl}/messages/unarchive`;
    const body = { ids };
    return this.http.post(url, body, { observe: 'response', withCredentials: true}).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  searchProviders(query: RecipientSearchQuery): Observable<RecipientList> {
    const url = `${this.env.apiUrl}/messages/search/recipients`;
    return this.http.post(url, query, { observe: 'response', withCredentials: true}).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  sendReplyMessage(msg: ReplyMessage): Observable<any> {
    const url = `${this.env.apiUrl}/messages/send`;
    msg.Subject = this.cleanMessage(msg.Subject);
    msg.Text = this.cleanMessage(msg.Text);
    return this.http.post(url, msg, { observe: 'response', withCredentials: true}).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  sendMedicalQuestion(msg: SendableMessage): Observable<any> {
    const url = `${this.env.apiUrl}/messages/send/medicalQuestion`;
    msg.Subject = this.cleanMessage(msg.Subject);
    msg.Text = this.cleanMessage(msg.Text);
    return this.http.post(url, msg, { observe: 'response', withCredentials: true}).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  sendGeneralQuestion(msg: SendableMessage): Observable<any> {
    const url = `${this.env.apiUrl}/messages/send/generalQuestion`;
    msg.Subject = this.cleanMessage(msg.Subject);
    msg.Text = this.cleanMessage(msg.Text);
    return this.http.post(url, msg, { observe: 'response', withCredentials: true}).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  getFavoriteProviders(msgType: 'medical' | 'general'): Observable<any> {
    const url = `${this.env.apiUrl}/messages/search/recipients`;
    const body = { Favorites: true, MessageType: msgType };
    return this.http.post(url, body, { observe: 'response', withCredentials: true}).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  uploadDocument(file: File): Promise<DocumentUploadResponse>{
    return new Promise(async (resolveUpload, rejectUpload) => {
      try {
        const uploadResponse = await firstValueFrom(this.uploadDocumentToServer(file));
        const maxStatusChecks = 10;
        const checkDocumentStatus = async (tryCount: number) => {
          const status = await firstValueFrom(this.getDocumentStatus(uploadResponse.DocumentID));
          if (status.ScanStatus === 'PASS') {
            resolveUpload(Object.assign({ FileName: file.name, status, uploadResponse}));
          } else if (status.ScanStatus === 'IN_PROCESS' && tryCount < maxStatusChecks) {
            setTimeout(async () => checkDocumentStatus(tryCount + 1), 1000);
          } else {
            rejectUpload('The file upload may contain malware.')
          }
        }
        checkDocumentStatus(0);
      } catch (err) {
        rejectUpload(err);
      }
    });
  }

  private uploadDocumentToServer(file: File): Observable<UploadedDocument> {
    const url = `${this.env.apiUrl}/documents/upload`;
    const formData = new FormData();
    formData.append('filename', file.name);
    formData.append('file', file);
    const headers = { 'BH-DONT-ASSIGN-HEADERS': 'true' };
    return this.http.post(url, formData, { observe: 'response', headers, withCredentials: true}).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  private getDocumentStatus(docId: number): Observable<ScanStatus> {
    const url = `${this.env.apiUrl}/documents/status/${docId}`;
    return this.http.get(url, { observe: 'response', withCredentials: true}).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  getAttachmentDownloadUrl(id: number) {
    const url = `${this.env.apiUrl}/messages/attachments/${id}`;
    return this.http.get(url, { observe: 'response', withCredentials: true}).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  openPrepopulatedMessage(message: PrepopulatedMessage) {
    message.body = this.cleanMessage(message.body);
    message.subject = this.cleanMessage(message.subject);
    this.prepopulatedMessage = message;
    this.router.navigateByUrl('/tabs/messages/new');
  }

  sortByMoment(a: string, b: string, dateFormat = 'YYYY-MM-DD HH:mm:ss') {
    const momentA = moment(a, dateFormat);
    const momentB = moment(b, dateFormat);
    if (momentA < momentB) {
      return 1;
    } else if (momentA > momentB) {
      return -1;
    } else {
      return 0;
    }
  }

}
