import { Pipe, PipeTransform } from '@angular/core';
import { HelperUtilitiesService } from '../services/_core/helper-utilities/helper-utilities.service';

@Pipe({
  name: 'formatObsUnits'
})
export class FormatObsUnitsPipe implements PipeTransform {

  transform(value: number, units: string, args?: any): any {
    switch (units) {
      case '[degF]':
        return value + ' &deg;';

      case 'kg':
        return this.kgToPounds(value) + ' lbs';

      case 'cm':
        return this.cmToFeetAndInches(value);

      case 'kg/m2':
        return value;

      default:
        return value + ' ' + units;
    }
  }

  kgToPounds(kg) {
    return Math.round(kg * 2.20462);
  }

  cmToFeetAndInches(cm) {
    const totalInches = cm * 0.393701; // Convert cm to inches
    const feet = Math.floor(totalInches / 12); // Get the whole number of feet
    const inches = Math.round(totalInches % 12); // Get the remainder in inches and round to 2 decimal places
    return feet + '\' ' + inches + '"';
  }
}
