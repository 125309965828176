<ion-list>
  <ion-list-header mode="ios" class="margin-top-24 margin-bottom-16">
    <a href="javascript:void(0)" (click)="openRoute('/tabs/messages')">Messages</a>
  </ion-list-header>
  <ion-item button (click)="openFeature(f)" *ngFor="let f of messageFeatures">
    <img [src]="'/assets/baystate-icons/' + f.icon" slot="start" class="thumbnail" />
    <ion-label>{{ f.name }}</ion-label>
  </ion-item>

  <ion-list-header mode="ios" class="margin-top-24 margin-bottom-16">
    <a href="javascript:void(0)" (click)="openRoute('/tabs/health-record')">Health Record</a>
  </ion-list-header>
  <ion-item button (click)="openFeature(f)" *ngFor="let f of healthRecordFeatures">
    <img [src]="'/assets/baystate-icons/' + f.icon" slot="start" class="thumbnail" />
    <ion-label>{{ f.name }}</ion-label>
  </ion-item>

  <ion-list-header mode="ios" class="margin-top-24 margin-bottom-16">
    <a href="javascript:void(0)" (click)="openRoute('/tabs/resources')">Resources</a>
  </ion-list-header>
  <ion-item button (click)="openFeature(f)" *ngFor="let f of resourceFeatures">
    <img [src]="'/assets/baystate-icons/' + f.icon" slot="start" class="thumbnail" />
    <ion-label>{{ f.name }}</ion-label>
  </ion-item>
</ion-list>
