<div [ngClass]="{'small-form': smallForm}">
  <ion-icon
    [name]="ionIcon"
    [color]="ionIconColor"
    class="confirmation-icon"
    *ngIf="ionIcon"
  ></ion-icon>
  <h3 *ngIf="heading" innerHtml="{{ heading | translate }}"></h3>
  <p *ngIf="message" innerHtml="{{ message | translate }}"></p>
  <div>
    <ion-button
      (click)="onClick()"
      [fill]="buttonFill"
      *ngIf="showButton"
    >
      <ion-icon [name]="buttonIcon" slot="start" (click)="onClick()" *ngIf="buttonIcon"></ion-icon>
      {{ buttonLabel | translate }}
    </ion-button>
  </div>
</div>
