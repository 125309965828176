import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonLabel, IonList, IonItem, IonListHeader, ModalController } from "@ionic/angular/standalone";
import { Feature } from 'src/app/models/feature';
import { IframeModalPage } from 'src/app/pages/iframe-modal/iframe-modal.page';
import { FeatureService } from 'src/app/services/feature/feature.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';

@Component({
  selector: 'bh-site-index',
  templateUrl: './bh-site-index.component.html',
  styleUrls: ['./bh-site-index.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonList,
    IonList,
    IonItem,
    IonLabel,
    IonListHeader,
  ]
})
export class BhSiteIndexComponent  implements OnInit {
  @Output() clickEvent: EventEmitter<Feature> = new EventEmitter();
  healthRecordFeatures: Feature[] = [];
  messageFeatures: Feature[] = [];
  resourceFeatures: Feature[] = [];

  constructor(
    private featureSvc: FeatureService,
    private navService: NavigationService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this.healthRecordFeatures = this.featureSvc.features.filter(f => f.category === 'HEALTH RECORD');
    this.messageFeatures = this.featureSvc.features.filter(f => f.category === 'MESSAGES');
    this.resourceFeatures = this.featureSvc.features.filter(f => f.category === 'RESOURCES');
  }

  openRoute(url) {
    this.navService.navigateForward(url);
    this.clickEvent.emit();
  }

  async openIframeModal(f: Feature) {
    const modal = await this.modalCtrl.create({
      component: IframeModalPage,
      componentProps: {
        heading: f.name,
        src: f.path
      },
      cssClass: 'wide-modal'
    });

    await modal.present();
  }

  openFeature(feature: Feature) {
    if (feature.presentation === 'iframe') {
      this.openIframeModal(feature);
    } else if (feature.presentation === 'external') {
      window.open(feature.path, '_blank');
    } else {
      this.navService.navigateForward(feature.path);
    }
    this.clickEvent.emit();
  }

}
