import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { PatientForm } from 'src/app/models/patient-form';
import { PatientFormProperties } from 'src/app/models/patient-form-properties';
import { PatientFormSubmission } from 'src/app/models/patient-form-submission';
import { PatientFormsResponse } from 'src/app/models/patient-forms-response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PatientFormsService {
  env = environment;
  selectedPatientForm: PatientForm;

  constructor(
    private http: HttpClient
  ) { }

  getAllForms(): Observable<PatientFormsResponse> {
    const url = `${this.env.apiUrl}/forms/list`;
    return this.http.get(url, { observe: 'response', withCredentials: true}).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  getPrintableForms(): Observable<any> {
    const url = `${this.env.apiUrl}/library/public/forms`;
    return this.http.get(url, { observe: 'response', withCredentials: true}).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  getElectronicForms(): Observable<any> {
    const url = `${this.env.apiUrl}/forms/definitions/hscomm`;
    return this.http.get(url, { observe: 'response', withCredentials: true}).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  getElectronicForm(formKey: string): Observable<PatientFormProperties> {
    const url = `${this.env.apiUrl}/forms/definitions/hscomm/${formKey}`;
    return this.http.get(url, { observe: 'response', withCredentials: true}).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  getSubmittedFormsAll(): Observable<PatientFormSubmission[]> {
    const url = `${this.env.apiUrl}/forms/data/hscomm/user/getSubmittedForm/list`;
    return this.http.get(url, { observe: 'response', withCredentials: true}).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  getSubmittedForm(formKey: string): Observable<any> {
    const url = `${this.env.apiUrl}/forms/data/hscomm/user/getSubmittedForm/id?id=${formKey}`;
    return this.http.get(url, { observe: 'response', withCredentials: true}).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  submitForm(userId: string, formData: string): Observable<any> {
    const url = `${this.env.apiUrl}/forms/data/hscomm/user/${userId}/_submit`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(url, formData, { observe: 'response', withCredentials: true, headers}).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  // getSubmittedForms(): Observable<any> {
  //   const url = `${this.env.apiUrl}/forms/definitions/hscomm/getForms/user/2019`;
  //   return this.http.get(url, { observe: 'response', withCredentials: true}).pipe(
  //     map((data: any) => {
  //       return data.body;
  //     }),
  //   );
  // }


}
