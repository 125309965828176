import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DateHandlerService } from '../date-handler/date-handler.service';
import { ShareTestResult } from 'src/app/models/share-test-result';
import { TestResult } from 'src/app/models/test-result';
import { LabTestResultResponse, OtherTestResultResponse, RadTestResultResponse } from 'src/app/models/test-result-response';
import { TestResultItem } from 'src/app/models/test-result-item';

@Injectable({
  providedIn: 'root'
})
export class TestResultsService {
  env = environment;
  lastCode: string;
  testResultHistory: any[] = [];
  chartDataCache: any[] = [];
  lastQuantity: number;
  newTestResults: BehaviorSubject<any[]> = new BehaviorSubject([]);
  selectedRadResult: TestResult;
  selectedLabResult: TestResult;
  allLabResults: TestResult[] = [];

  constructor(
    private http: HttpClient,
    private dateHandler: DateHandlerService
  ) { }

  getTestResultsLabs(): Observable<LabTestResultResponse> {
    const url = `${this.env.apiUrl}/ehr/category/labOrders`;
    return this.http.get(url, { observe: 'response', withCredentials: true }).pipe(
      map((data: any) => {
        const res = data.body as LabTestResultResponse;
        const testResults = res.LabOrders;
        for (const tr of testResults) {
          tr.Components = '';
          if (tr.ResultItems) {
            let componentNames: string[] = [];
            for (const ri of tr.ResultItems) {
              this.setObservationInterpretation(ri);
              componentNames.push(ri.Test);
            }
            tr.Components = componentNames.join(', ');
          }
        }
        return data.body;
      }),
    );
  }

  setObservationInterpretation(ri: TestResultItem) {
    if (ri.ObservationValueType === 'NUM' || ri.ObservationValueType === 'NM') {
      // Parse range
      const refRange = ri.ReferenceRange.split('-');
      // Check for two number values
      if (refRange.length === 2) {
        ri.resultMinRange = Number(refRange[0]);
        ri.resultMaxRange = Number(refRange[1]);
        ri.resultOperator = 'BETWEEN';

        // Check if ResultInterpreation is empty, interpret it
        if (!ri.ResultInterpretation) {
          ri.ResultInterpretation = (ri.ResultValue < ri.resultMinRange) ? 'Low' :
            (ri.ResultValue > ri.resultMaxRange) ? 'High' : 'Normal';
        }

        // Check that ResultValue is between resultMinRange and resultMaxRange
        if (ri.ResultValue >= ri.resultMinRange && ri.ResultValue <= ri.resultMaxRange) {
          ri.resultMeterPercent = ((ri.ResultValue - ri.resultMinRange) / (ri.resultMaxRange - ri.resultMinRange)) * 100;
        } else {
          // console.log('setObservationInterpretation: result value out of range', ri.resultMinRange, ri.resultMaxRange, ri.ResultValue);
          ri.resultMeterPercent = -9999;
        }
      } else if (refRange.length === 1) {
        ri.resultOperator = refRange[0].indexOf('>') > -1 ? 'GT' : refRange[0].indexOf('<') > -1 ? 'LT' : 'UNKNOWN';

        if (!ri.ResultInterpretation) {
          const refInt = this.extractNumber(ri.ReferenceRange);
          if (refInt > -9999) {
            if (ri.resultOperator == 'GT') {
              ri.ResultInterpretation = (ri.ResultValue > refInt) ? 'Normal' : 'Low';
            } else if (ri.resultOperator === 'LT') {
              ri.ResultInterpretation = (ri.ResultValue < refInt) ? 'Normal' : 'High';
            }
          }
        }
        // console.log('setObservationInterpretation: single operator range detected', ri.ObservationValueType, refRange, ri.ResultValue);
        ri.resultMeterPercent = -9999;
      } else {
        // console.log('setObservationInterpretation: reference range format invalid', ri.ObservationValueType, refRange, ri.ResultValue);
        ri.resultMeterPercent = -9999;
      }
    } else {
      // console.log('setObservationInterpretation: invalid data types', ri.ObservationValueType, ri.ResultValue, ri.ReferenceRange);
      ri.resultMeterPercent = -9999;
    }

  }

  extractNumber(input: string): number {
    // Define a regular expression to match a number preceded by any character
    const regex = /[<>](\d+)/;

    // Execute the regular expression on the input string
    const match = input.match(regex);

    // If a match is found, parse the number part
    if (match && match[1]) {
      return parseInt(match[1], 10);
    }

    // If no match is found, throw an error or handle it accordingly
    return -9999;
  }

  getTestResultsRads(): Observable<RadTestResultResponse> {
    const url = `${this.env.apiUrl}/ehr/category/radOrders`;
    return this.http.get(url, { observe: 'response', withCredentials: true }).pipe(
      map((data: any) => {
        const res = data.body as RadTestResultResponse;
        const testResults = res.RadOrders;
        for (const tr of testResults) {
          tr.Components = '';
        }
        return data.body;
      }),
    );
  }

  getTestResultsOther(): Observable<OtherTestResultResponse> {
    const url = `${this.env.apiUrl}/ehr/category/otherOrders`;
    return this.http.get(url, { observe: 'response', withCredentials: true }).pipe(
      map((data: any) => {
        const res = data.body as OtherTestResultResponse;
        const testResults = res.OtherOrders;
        for (const tr of testResults) {
          tr.Components = '';
        }
        return data.body;
      }),
    );
  }

  async getTestResultsAll(): Promise<TestResult[]> {
    try {
      const labRes = await firstValueFrom(this.getTestResultsLabs());
      const radRes = await firstValueFrom(this.getTestResultsRads());
      const otherRes = await firstValueFrom(this.getTestResultsOther());
      const labResults = labRes['LabOrders'] as any[];
      const radResults = radRes['RadOrders'] as any[];
      const otherResults = otherRes['OtherOrders'] as any[];
      let testResults = [];

      // Process lab results, set observation time to entered time if null
      if (labResults && labResults.length > 0) {
        labResults.map(orders => {
          if (orders && orders.ResultItems && orders.ResultItems.length > 0) {
            for (const r of orders.ResultItems) {
              if (!r.ObservationTime) {
                r.ObservationTime = orders.EnteredTime;
              }
            }
          }
          orders.labOrders = true;
        });
        testResults = testResults.concat(labResults);
      }

      // Combine lab and radiology results
      if (radResults && radResults.length > 0) {
        radResults.map(orders => orders.labOrders = false);
        testResults = testResults.concat(radResults);
      }

      // Add other results
      if (otherResults && otherResults.length > 0) {
        otherResults.map(orders => orders.labOrders = true);
        testResults = testResults.concat(otherResults);
      }

      // Return all test results
      return testResults;

    } catch (err) {
      console.error('getTestResultsAll', err);
      return Promise.reject(err);
    }
  }

  getPathPdfReport(reportId: string) {
    const url = `${this.env.apiUrl}/ehr/document/ehr/${reportId}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/pdf',
      'BH-DONT-ASSIGN-HEADERS': 'true'
    });
    return this.http.get(url, { observe: 'response', withCredentials: true, headers, responseType: 'blob' }).pipe(
      map((data: any) => {
        const blobUrl = URL.createObjectURL(data.body);
        return blobUrl;
      }),
    );
  }

  getChartDataWithQuantity(code, quantity) {
    if (quantity === 0) {
      return null;
    }
    if (quantity != this.lastQuantity) {
      return this.getChartDataInternal(code);
    } else {
      return this.chartDataCache;
    }
  }

  getChartData(code) {
    //only generate data if code changed
    if (this.lastCode != code) {
      return this.getChartDataInternal(code);
    } else {
      return this.chartDataCache;
    }
  }

  private getChartDataInternal(code) {
    let chartdata = [];
    let seriesChart = [];
    let historyData = this.testResultHistory
      .filter(resultItem => resultItem.Code == code)
      .sort((a, b) => b.ObservationTime < a.ObservationTime ? 1 : -1);
    this.lastQuantity = this.testResultHistory.length;
    for (let index = 0; index < historyData.length; index++) {
      const element = historyData[index];
      seriesChart.push({
        name: this.dateHandler.parseDateString(element.ObservationTime),
        value: element.ResultValue
      });
    }
    if (historyData.length > 0) {
      chartdata.push({
        name: historyData[0].Test,
        series: seriesChart
      });
    }

    this.chartDataCache = chartdata;
    this.lastCode = code;
    return chartdata;
  }

  CheckIfHasString(code) {
    let historyData = this.testResultHistory.filter(resultItem => resultItem.Code == code);
    let result = historyData.some(function (element) {
      var number = Number(element.ResultValue);
      return isNaN(number);
    });
    return result;
  }

  getResultClass(cellData) {
    if (cellData.column.prop == 'ResultValue') {
      if (cellData.row.ResultValue != null) {
        try {
          if (cellData.row.ResultValue.match(/\d+/g) == null) {
            return ' hide-arrow';
          }
        } catch (e) {
          console.error('getResultClass', e);
        }
      }
      if (cellData.row.ResultInterpretation.indexOf('High') > -1) {
        return ' test-result-not-in-range arrow-up';
      } else if (cellData.row.ResultInterpretation.indexOf('Low') > -1) {
        return ' test-result-not-in-range arrow-down';
      } else {
        return ' test-result-in-range hide-arrow';
      }
    } else {
      if (cellData.column.prop == 'ResultInterpretation') {
        if (cellData.row.ResultInterpretation.indexOf('High') > -1 || cellData.row.ResultInterpretation.indexOf('Low') > -1) {
          return ' test-result-not-in-range ';
        }
      }
    }
    return '';
  }

  async shareTestResultsWithProvider(testResultData: ShareTestResult) {
    const url = `${this.env.apiUrl}/customapi/emailToProvider`;
    const body = testResultData;
    return this.http.post(url, body, { observe: 'response', withCredentials: true }).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  /** Returns the last login date or null */
  getLastLoginDate() {
    const url = `${this.env.apiUrl}/ehr/category/counts`;
    return this.http.get(url, { observe: 'response', withCredentials: true }).pipe(
      map((data: any) => {
        if (!data.body.LastLogin) throw new Error('server did not return a last login date');
        const lastLogin = data.body.LastLogin;
        const isoDateString = (lastLogin as string)
          .replace(' ', 'T')
          .substring(0, lastLogin.indexOf('.'))
          .concat('Z'); // this date is returned in GMT
        const lastLoginDate = new Date(isoDateString);
        return lastLoginDate;
      }),
    );
  }

  /** Returns the number of new test results since last login */
  async getNewTestResults(): Promise<any> {
    try {
      const [testResults, lastLoginDate] = await Promise.all([this.getTestResultsAll(), firstValueFrom(this.getLastLoginDate())]);
      // if this is the first time the user logged in consider all test results new
      if (!lastLoginDate) return testResults.length;
      const newTestResults = testResults.filter(testResult => {
        const testResultDate = new Date(testResult.Date);
        return testResultDate.getTime() > lastLoginDate.getTime()
      });
      this.newTestResults.next(newTestResults)
      return newTestResults;
    }
    catch (err) {
      console.error('getNewTestResults: Error loading new test results', err);
      return []; // if fetching count fails do not show any new count indicator
    }
  }

}
