<ion-header>
  <ion-toolbar>
    <ion-title>{{ heading | translate }}</ion-title>
    <ion-buttons slot="end">

      <ion-button (click)="download()" shape="round" *ngIf="base64Pdf">
        <ion-icon name="download-outline"></ion-icon>
      </ion-button>
      <ion-button (click)="dismiss()" shape="round">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <iframe [src]="src | safe: 'resource-url'" *ngIf="src"></iframe>
  <div class="no-src-message" *ngIf="!src">
    {{ 'No content URL provided.' | translate }}
  </div>
</ion-content>
