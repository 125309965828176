import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateHandlerService {

  constructor() { }

  /**
  * Function for parsing dates, use this instead of new Date(dateString).
  * Needed because Safari can't natively parse the dates coming from the personal community API.
  */
  parseDateString(dateString: string | Date): Date {
    if (!dateString) return null;
    try {

      if (dateString.toString().length < 12 && dateString.toString().indexOf("/") == -1) {
        dateString = dateString + "T00:00:00"
      }

      const date = moment(dateString).toDate();
      if (isNaN(date.getTime())) throw new Error;
      return date;
    }
    catch (error) {

      try {
        if (typeof dateString != 'string') throw new Error('unexpected value parsing date');
        const intersystemsDateRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/;
        if (intersystemsDateRegex.test(dateString)) {
          const iso = dateString.slice(0, 10) +
            (dateString.length > 10 ? 'T' : '') +
            dateString.slice(11, 19);
          return moment(iso).toDate();
        }
        throw new Error('failed to parse date');
      }
      catch (error) {
        throw error;
      }
    }
  }

  getElapsedTime(dateTime: string): string {
    const dateTimeMoment = moment(dateTime, 'MM/DD/YYYY hh:mm a');
    let elapsedTime = '';
    if (dateTimeMoment.isValid()) {
      const nowMoment = moment();
      const minutesElapsed = nowMoment.diff(dateTimeMoment, 'minute')
      elapsedTime = this.convertMinutesToUserFriendlyTime(minutesElapsed);
    }
    return elapsedTime;
  }

  convertMinutesToUserFriendlyTime(minutes: number) {
    let pluralS = '';
    if (minutes === 0) {
      return 'Just now';
    } else if (minutes < 60) {
      pluralS = minutes > 1 ? 's' : '';
      return minutes + ' minute' + pluralS + ' ago';
    } else {
      const hours = Math.ceil(minutes / 60);
      if (hours < 24) {
        pluralS = hours > 1 ? 's' : '';
        return hours + ' hour' + pluralS + ' ago';
      } else {
        const days = Math.ceil(hours / 24);
        pluralS = days > 1 ? 's' : '';
        if (days < 30) {
          if (days > 7) {
            const weeks = Math.ceil(days / 7);
            pluralS = weeks > 1 ? 's' : '';
            return weeks + ' week' + pluralS + ' ago';
          } else {
            return days + ' day' + pluralS + ' ago';
          }
        } else {
          const months = Math.ceil(days / 30);
          pluralS = months > 1 ? 's' : '';
          if (months > 12) {
            const years = Math.ceil(months / 12);
            pluralS = years > 1 ? 's' : '';
            return years + ' year' + pluralS + ' ago';
          } else {
            return months + ' month' + pluralS + ' ago';
          }
        }
      }
    }
  }

  sortByMoment(a: string, b: string, dateFormat = 'YYYY-MM-DD HH:mm:ss', orderBy: 'newest' | 'oldest' = 'newest') {
    const momentA = moment(a, dateFormat);
    const momentB = moment(b, dateFormat);
    if (momentA.isBefore(momentB)) {
      return orderBy === 'oldest' ? -1 : 1;
    } else if (momentA.isAfter(momentB)) {
      return  orderBy === 'oldest' ? 1 : -1;
    } else {
      return 0;
    }
  }

  formatTimestamp(timestamp) {
    return moment(timestamp, 'YYYY-MM-DD HH:mm:ss').format('MMMM D, YYYY h:mm a')
  }

}
