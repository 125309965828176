import { Injectable } from '@angular/core';
import { LegacyLinkMap } from 'src/app/models/legacy-link-map';
import { NotificationsService } from '../_core/notifications/notifications.service';

@Injectable({
  providedIn: 'root'
})
export class LegacyLinksService {
  linkMaps: LegacyLinkMap[] = [
    {
      oldPath: 'activate-account',
      newPath: 'signup/activate'
    },
  ];

  constructor(
  ) { }

  getNewLink(oldPath: string) {
    let path = oldPath;
    if (oldPath) {
      const linkMap = this.linkMaps.find(lm => lm.oldPath.trim().toLowerCase() === oldPath.trim().toLowerCase());
      if (linkMap) {
        console.log('Legacy path detected', oldPath, path);
        path = linkMap.newPath;
      }
    }
    return path;
  }
}
