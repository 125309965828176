import { IonIcon, IonButton } from '@ionic/angular/standalone';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PipesModule } from 'src/app/pipes/pipes.module';

@Component({
  selector: 'bh-confirmation',
  templateUrl: './bh-confirmation.component.html',
  styleUrls: ['./bh-confirmation.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonIcon,
    IonButton,
    PipesModule
  ]
})
export class BhConfirmationComponent  implements OnInit {
  @Input() ionIcon = 'checkmark-circle-outline';
  @Input() ionIconColor: 'success' | 'warning' | 'danger' | 'primary' | 'secondary' | 'tertiary' = 'success';
  @Input() heading = '';
  @Input() message = '';
  @Input() showButton = false;
  @Input() buttonLabel = 'Continue';
  @Input() buttonIcon;
  @Input() buttonFill: 'solid' | 'outline' = 'solid';
  @Input() smallForm = false;
  @Output() clickEvent = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  onClick() {
    this.clickEvent.emit();
  }

}
