<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="primary" (click)="dismiss()">{{ 'Cancel' | translate }}</ion-button>
    </ion-buttons>

    <ion-title class="text-align-center">{{ 'Change Language' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button color="primary" fill="solid" (click)="setLanguage()">{{ 'Save' | translate }}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" [fullscreen]="true">
  <form [formGroup]="form1">
    <h3>
      {{ 'Choose Your Preferred Language' | translate }}
    </h3>
    <bh-input
      [formGroup]="form1"
      formControlName="language"
      type="radio-list"
      [selectOptions]="languages"
      selectValueProperty="code"
      selectLabelProperty="native"
      [hideBorder]="true"
    ></bh-input>
  </form>
</ion-content>
