import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonIcon, ModalController } from '@ionic/angular/standalone';
import { PipesModule } from 'src/app/pipes/pipes.module';
import * as FileSaver from 'file-saver';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { ErrorHandlerService } from 'src/app/services/_core/error-handler/error-handler.service';

@Component({
  selector: 'app-iframe-modal',
  templateUrl: './iframe-modal.page.html',
  styleUrls: ['./iframe-modal.page.scss'],
  standalone: true,
  imports: [
    IonIcon,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    CommonModule,
    FormsModule,
    PipesModule
  ]
})
export class IframeModalPage implements OnInit {
  @Input() heading;
  @Input() src;
  @Input() base64Pdf: string;
  @Input() fileDownloadName: string;
  dataBlob: Blob;

  constructor(
    private modalCtrl: ModalController,
    private notifications: NotificationsService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit() {
    this.checkForBase64Pdf();
  }

  checkForBase64Pdf() {
    if (this.base64Pdf) {
      const binary = atob(this.base64Pdf.replace(/\s/g, ''));
      const len = binary.length;
      const buffer = new ArrayBuffer(len);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }
      const blob = new Blob([view], {
        type: "application/pdf"
      });
      this.dataBlob = blob;
      const url = URL.createObjectURL(blob);
      this.src = url;
    }
  }

  download() {
    try {
      FileSaver.saveAs(this.dataBlob, this.fileDownloadName);
      this.notifications.showAlert('File downloaded', 'Check your device\'s Downloads folder.', 'success')
    } catch (err) {
      this.errorHandler.handleError(err, 'iframe-modal.download');
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

}
